import React from "react"
import { useLocation } from "@reach/router"
import "../layout.css"
import { NavigationBar } from "../NavigationBar/parent"
import PrivacySingaporeMain from "./PrivacySingaporeMain"
import Footer from "../FooterComponents/Footer"

import { MainPageJson, SingaporePrivacyPolicyJson, PathJson } from "../location"
import Seo from "../SEOComponents/Seo"
import { LayoutTextPage } from "../LayoutTextPage/parent"

function SgPrivacyPolicy() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const PrivacyContent = SingaporePrivacyPolicyJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const { Title, Title1, Title2 } = PrivacyContent.Header || {}

  return (
    <div className="maxwidth">
      <Seo title="sg-privacy-policy" />
      <NavigationBar />
      <LayoutTextPage title={Title} secondTitle={Title1} thirdTitle={Title2}>
        <PrivacySingaporeMain props={PrivacyContent} propsLink={PathContent} />
      </LayoutTextPage>
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default SgPrivacyPolicy
